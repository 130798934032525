// src/MeuComponente.js
import React, { useEffect, useState } from 'react';
import { SearchBar, Page } from 'components';
import Results from './components/Results';

import Api from 'services/Api';
import ApiGServer from 'services/ApiGServer';
import Reload from '../../components/Reload/Reload';
import Header from '../../components/Header/Header';

const SuporteDry = () => {
  const user = JSON.parse(localStorage.getItem('userData'))
  if (user?.permission?.supportdry?.read !== true) {
    window.location.replace('/home');
  }

  const [filterEquipaments, setFilterEquipaments] = useState([]);
  const [allEquipaments, setAllEquipaments] = useState([]);
  const [searchData, setSearchData] = useState('');
  const [permission, setPermission] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await Api.get('/connection/connected');

        const filteredData = response.data.filter(item => item.includes("SEMT"));
        //handleDetailEquipaments(filteredData.sort((a, b) => a.localeCompare(b)));

        setFilterEquipaments(filteredData);
        setAllEquipaments(filteredData);
        setLoading(false)
      } catch (error) {
        console.error('Erro ao buscar os dados:', error);
        setLoading(false)
      }
    };
    fetchData();

    const interval1 = setInterval(() => {
      fetchData();
    }, 60000);
    return () => { clearInterval(interval1) };
  }, []);

  const handleDetailEquipaments = async (equipments) => {
    try {
      const { data } = await ApiGServer.post(`/equipment/filter`, { filter: equipments });
      setFilterEquipaments(data)
      setAllEquipaments(data)
    } catch (error) {
      console.log(error);
      setLoading(false)
    }
    setLoading(false)
  };

  const handleSearch = () => {
    if (searchData === '') {
      setAllEquipaments(filterEquipaments);
    } else {
      const equipamentsSearch = [];
      filterEquipaments.forEach((company) => {
        if ((company.toLowerCase().includes(searchData.toLowerCase()))) {
          equipamentsSearch.push(company)
        }
      });
      setAllEquipaments(equipamentsSearch);
    }
  };

  const handleSearchData = (e) => {
    setSearchData(e.target.value)
  };

  return (
    <Page className="p-3" title="Suporte Dry">
      <Header page={"Suporte Dry"} />
      <SearchBar
        onSearch={handleSearch}
        onSearchData={handleSearchData}
        searchData={searchData}
      />

      {loading && <Reload />}

      {allEquipaments && (
        <Results
          data={allEquipaments}
          permission={permission}
        />
      )}
    </Page >
  );
}

export default SuporteDry;
