import React, { useEffect, useState } from 'react';
import { Alert } from 'components';
import { getToken } from 'actions/sessionActions';

import {
  Row,
  Col,
  Card,
  Form,
  Table,
  Button,
  FloatingLabel,
} from 'react-bootstrap';

import ApiGServer from 'services/ApiGServer';

const Forms = (props) => {
  const {
    alertMessage,
    equipments,
    fieldChange,
    onSubmit,
    permission,
    checkboxChange,
    checkboxIsentChange,
    setAlertMessage,
    empresa,
    handlePlan
  } = props;

  const [enable, setEnable] = useState(false)
  const [link, setLink] = useState('')

  const handlePlanChange = (event) => {
    handlePlan(event.target.value)
  };

  useEffect(() => {
    if (empresa && empresa.typeCompany) {
      setLink(`/cooperatives/${empresa._id}`)
    } else {
      setLink(`/units/${empresa._id}`)
    }
  }, [empresa])

  const handleChange = (event) => {
    if (event.target.checked === true) {
      if (equipments.serialnumber.includes("SIMT")) {
        fieldChange(event, 'daystoactivate', 28)
        handleDaysChange(28)
      }
      if (equipments.serialnumber.includes("BIMT") || equipments.serialnumber.includes("CPMT")) {
        fieldChange(event, 'daystoactivate', 1)
        handleDaysChange(1)
      }
      if (equipments.serialnumber.includes("EEMT")) {
        fieldChange(event, 'daystoactivate', 5)
        handleDaysChange(5)
      }
    }
    checkboxChange(event.target.checked);
  };

  const handleDaysChange = async (data) => {
    try {
      await ApiGServer.post(`/equipment/updatedaysactivation`, {
        serialnumber: equipments.serialnumber,
        daystoactivate: data
          .toString(16)
          .toUpperCase()
      });
      setAlertMessage({ message: 'Atualizado com Sucesso!', type: 'success' });
    } catch (e) {
      setAlertMessage({ message: 'Falha ao atualizar!', type: 'error' });
    }
  }

  return (
    <Card className='mb-3 mt-3'>

      {alertMessage.message ?
        <Alert
          message={alertMessage.message}
          variant={alertMessage.type}
        />
        : null
      }

      <Card.Header>
        <h5 className='mt-2 mb-2'>Dados do Equipamento</h5>
      </Card.Header>
      <Card.Body>
        <Form>
          <Row className="g-2">
            <label className='mb-2'>Empresa:
              {empresa ?
                <a href={link} style={{ color: "green" }}>
                  {" "}{empresa.name}
                </a>
                : ' --'
              }
            </label>

            <Col md>
              <FloatingLabel controlId="floatingInput" label="Número de Série" className="mb-3">
                <Form.Control
                  type="text"
                  name="Número de Série"
                  placeholder="Número de Série"
                  value={equipments?.serialnumber || '--'}
                  disabled
                />
              </FloatingLabel>
            </Col>
            <Col md>
              <FloatingLabel controlId="floatingInput" label="ID" className="mb-3">
                <Form.Control
                  type="text"
                  name="ID"
                  placeholder="ID"
                  value={equipments?.equipid || '--'}
                  disabled
                />
              </FloatingLabel>
            </Col>
            <Col md>
              <FloatingLabel controlId="floatingInput" label="Curva" className="mb-3">
                <Form.Control
                  type="text"
                  name="Curva"
                  placeholder="Curva"
                  value={equipments?.sha1curva || '--'}
                  disabled
                />
              </FloatingLabel>
            </Col>
            <Col md>
              <FloatingLabel controlId="floatingInput" label="Data de Calibração" className="mb-3">
                <Form.Control
                  type="text"
                  name="Data de Calibração"
                  placeholder="Data de Calibração"
                  value={equipments?.dcal || '--'}
                  disabled
                />
              </FloatingLabel>
            </Col>
          </Row>

          <Row className="g-2">
            <Col md>
              <FloatingLabel controlId="floatingInput" label="Curva Principal" className="mb-3">
                <Form.Control
                  type="text"
                  name="Curva Principal"
                  placeholder="Curva Principal"
                  value={equipments?.charts ? equipments?.charts?.principal : '--'}
                  disabled
                />
              </FloatingLabel>
            </Col>
            <Col md>
              <FloatingLabel controlId="floatingInput" label="Curva Complementar" className="mb-3">
                <Form.Control
                  type="text"
                  name="Curva Complementar"
                  placeholder="Curva Complementar"
                  value={equipments?.charts ? equipments?.charts?.complementar : '--'}
                  disabled
                />
              </FloatingLabel>
            </Col>
            <Col md>
              <FloatingLabel controlId="floatingInput" label="Versão do Software" className="mb-3">
                <Form.Control
                  type="text"
                  name="Versão do Software"
                  placeholder="Versão do Software"
                  value={equipments?.versaosw || '--'}
                  disabled
                />
              </FloatingLabel>
            </Col>
            <Col md>
              <FloatingLabel controlId="floatingInput" label="ID do Copo" className="mb-3">
                <Form.Control
                  type="text"
                  name="ID do Copo"
                  placeholder="ID do Copo"
                  value={equipments?.cupid || '--'}
                  disabled
                />
              </FloatingLabel>
            </Col>
          </Row>

          <Row className="g-2">
            <Col md>
              <FloatingLabel controlId="floatingInput" label="Versão do Firmware Principal" className="mb-3">
                <Form.Control
                  type="text"
                  name="Versão do Firmware Principal"
                  placeholder="Versão do Firmware Principal"
                  value={equipments?.versaofwpri || '--'}
                  disabled
                />
              </FloatingLabel>
            </Col>
            <Col md>
              <FloatingLabel controlId="floatingInput" label="Versão do Firmware Osciladora" className="mb-3">
                <Form.Control
                  type="text"
                  name="Versão do Firmware Osciladora"
                  placeholder="Versão do Firmware Osciladora"
                  value={equipments?.versaofwosc || '--'}
                  disabled
                />
              </FloatingLabel>
            </Col>
            <Col md>
              <FloatingLabel controlId="floatingInput" label="Tamanho do Copo" className="mb-3">
                <Form.Control
                  type="text"
                  name="Tamanho do Copo"
                  placeholder="Tamanho do Copo"
                  value={
                    Number(equipments.copo) === 0 ? "Sem dados" :
                      Number(equipments.copo) === 150 ? `Pequeno | ${Number(equipments.copo)}` :
                        Number(equipments.copo) > 150 ? `Pequeno | ${Number(equipments.copo)}` :
                          Number(equipments.copo) < 150 ? `Grande | ${Number(equipments.copo)}` : "--"}
                  disabled
                />
              </FloatingLabel>
            </Col>
            <Col md>
              <FloatingLabel controlId="floatingInput" label="Versão do Termistor" className="mb-3">
                <Form.Control
                  type="text"
                  name="Versão do Termistor"
                  placeholder="Versão do Termistor"
                  value={
                    Number(equipments.copo) === 0 ? "Sem dados" :
                      Number(equipments.copo) === 150 ? "Rev 0" :
                        Number(equipments.copo) > 150 ? "Rev 1" :
                          Number(equipments.copo) < 150 ? "Rev 0" : "--"}
                  disabled
                />
              </FloatingLabel>
            </Col>
          </Row>

          <Row className="g-2 mb-2">
            <Col xs="auto">
              <Form.Check
                type="checkbox"
                label="Wifi"
                checked={equipments.wf === 'OK'}
                disabled
                isValid
              />
            </Col>
            <Col xs="auto">
              <Form.Check
                type="checkbox"
                label="PH Calibrado"
                checked={equipments.calph === 'OK'}
                disabled
                isValid
              />
            </Col>
          </Row>

          <Row className="g-2">
            <Col xs={12} sm={6} md={6} lg={3} xl={3} xxl={3}>
              <FloatingLabel controlId="floatingInput" label="Dias para Ativação" className="mb-3">
                <Form.Control
                  type="text"
                  name="daystoactivate"
                  placeholder="Dias para Ativação"
                  value={equipments?.daystoactivate || ''}
                  onChange={(event) => {
                    setEnable(true)
                    fieldChange(event, 'daystoactivate', event.target.value)
                  }}
                />
              </FloatingLabel>
            </Col>
            <Col xs={12} sm={6} md={6} lg={9} xl={9} xxl={9}>
              <FloatingLabel controlId="floatingInput" label="Observação" className="mb-3">
                <Form.Control
                  type="text"
                  name="observation"
                  placeholder="Observação"
                  value={equipments?.observation || ''}
                  onChange={(event) => {
                    setEnable(true)
                    fieldChange(event, 'observation', event.target.value)
                  }}
                />
              </FloatingLabel>
            </Col>
          </Row>

          <Row className="g-2">
          </Row>

          <Row className='g-2'>
            <Col xs="auto">
              <Button
                className='mb-3'
                disabled={!permission}
                onClick={() => onSubmit()}
                variant="success"
              >
                Salvar
              </Button>
            </Col>
          </Row>

          <Row className="g-2">
            <Col xs="auto">
              <Form.Label>Modo: </Form.Label>
            </Col>
            <Col xs="auto">
              <Form.Check
                type="checkbox"
                label="LOCAÇÃO"
                checked={equipments?.mode === 'RENTAL'}
                onChange={handleChange}
                disabled={!permission}
                isValid
              />
            </Col>
            <Col xs="auto">
              <Form.Check
                type="checkbox"
                label="ISENÇÃO DE TAXAS"
                checked={equipments?.isentkey}
                onChange={(event) => checkboxIsentChange(event.target.checked)}
                disabled={!permission}
                isValid
              />
            </Col>
          </Row>

          <Row className="g-2">
            <Col xs="auto">
              <Form.Label>Plano: </Form.Label>
            </Col>
            <Col>
              <Form.Group>
                {equipments?.mode === "RENTAL" ? (
                  <>
                    <Form.Check
                      inline
                      type="radio"
                      label="NORMAL"
                      value="NORMAL"
                      name="plan"
                      checked={equipments?.plan === "NORMAL"}
                      onChange={handlePlanChange}
                      disabled={!permission}
                      isValid
                    />
                    <Form.Check
                      inline
                      type="radio"
                      label="FOB"
                      value="FOB"
                      name="plan"
                      checked={equipments.plan === "FOB"}
                      onChange={handlePlanChange}
                      disabled={!permission}
                      isValid
                    />
                  </>
                ) : (
                  <>
                    <Form.Check
                      inline
                      type="radio"
                      label="NORMAL"
                      value="NORMAL"
                      name="plan"
                      checked={equipments?.plan === "NORMAL"}
                      onChange={handlePlanChange}
                      disabled={!permission}
                      isValid
                    />
                    <Form.Check
                      inline
                      type="radio"
                      label="PLUS"
                      value="PLUS"
                      name="plan"
                      checked={equipments.plan === "PLUS"}
                      onChange={handlePlanChange}
                      disabled={!permission}
                      isValid
                    />
                    <Form.Check
                      inline
                      type="radio"
                      label="FLEX"
                      value="FLEX"
                      name="plan"
                      checked={equipments.plan === "FLEX"}
                      onChange={handlePlanChange}
                      disabled={!permission}
                      isValid
                    />
                    <Form.Check
                      inline
                      type="radio"
                      label="PREMIUM"
                      value="PREMIUM"
                      name="plan"
                      checked={equipments.plan === "PREMIUM"}
                      onChange={handlePlanChange}
                      disabled={!permission}
                      isValid
                    />
                  </>
                )}
              </Form.Group>
            </Col>
          </Row>
        </Form>
      </Card.Body>
    </Card >
  );
};

export default Forms;
