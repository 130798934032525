import React, { useState, useEffect } from 'react';
import dateformat from 'utils/dateformat';
import { Map, Filter } from './components';
import Reload from '../../components/Reload/Reload';
import ApiGServer from 'services/ApiGServer';
import ApiMServer from 'services/ApiMServer';
import Api from 'services/Api';

const GOOGLE_API_KEY = process.env.REACT_APP_GOOGLE_API_KEY;

const Maps = () => {
  const user = JSON.parse(localStorage.getItem('userData'));
  if (user?.permission?.maps?.read === false) {
    window.location.replace('/home');
  }

  const [zoom] = useState(5);
  const [allPoints, setAllPoints] = useState([]);
  const [points, setPoints] = useState([]);
  const [mapPoints, setMapPoints] = useState(false);
  const [center] = useState({ lat: -14.8227361, lng: -54.1549677 });
  const [companyNames, setCompanyNames] = useState([]);
  const [state, setState] = useState([])
  const [city, setCity] = useState([])
  const [companies, setCompanies] = useState([]);

  let [loading, setLoading] = useState(true);

  useEffect(() => {
    (async () => {
      let { data: equipments } = await ApiGServer.get('/equipment/all/filtered');
      let locatedList = [].concat.apply([], equipments.filter((e) => {
        return e.mode === 'RENTAL';
      }).map((i) => i.serialnumber));

      const response1 = await Api.get(`/connection/status`);
      const connectedEquips = response1.data;
      const connected = connectedEquips.map(item => item.serialnumber);

      const result = [];
      const pins = equipments.map(async (equipment) => {
        let lat = Number(parseFloat(equipment.lat).toFixed(7));
        let lng = Number(parseFloat(equipment.lng).toFixed(7));
        let date = dateformat(equipment.updatedAt);
        let serial = equipment.serialnumber;
        let versaosw = equipment.versaosw;
        let versaofwpri = equipment.versaofwpri;
        let versaofwosc = equipment.versaofwosc;
        let located = locatedList.includes(serial);
        let isConnected = connected.includes(equipment.serialnumber);
        let address = ''
        if (equipment.address) {
          address = equipment.address;
        }

        if (lat === 0 || lng === 0) return;

        if (!isNaN(lat) && !isNaN(lng)) {
          result.push({ serial, lat, lng, date, versaosw, versaofwpri, versaofwosc, located, isConnected, address });
        }
      })
      await Promise.all(pins);
      var points = result.map(item => ({
        located: item.located,
        type: "Feature",
        properties: {
          cluster: false,
          id: item.serial,
          data: item
        },
        geometry: {
          type: "Point",
          coordinates: [
            parseFloat(item.lng),
            parseFloat(item.lat)
          ]
        },
        address: item.address
      }))
      points = points.sort(function (a, b) {
        if (a.properties.data.serial > b.properties.data.stitleerial) {
          return 1;
        }
        if (a.properties.data.serial < b.properties.data.serial) {
          return -1;
        }
        return 0;
      })
      setPoints(points);
      setAllPoints(points);
      setLoading(false);

      const results = [];
      const pinss = equipments.filter(i => i.address && i.address.state)
        .map(async (equipment) => {
          let lat = Number(parseFloat(equipment.lat).toFixed(8));
          let lng = Number(parseFloat(equipment.lng).toFixed(8));
          let date = dateformat(equipment.updatedAt);
          let serial = equipment.serialnumber;
          let versaosw = equipment.versaosw;
          let versaofwpri = equipment.versaofwpri;
          let versaofwosc = equipment.versaofwosc;
          let located = locatedList.includes(serial);

          if (equipment.address !== "" && equipment.address.state !== null) {
            lat = Number(parseFloat(equipment.address.state.lat).toFixed(8));
            lng = Number(parseFloat(equipment.address.state.lng).toFixed(8));
          }
          if (lat === 0 || lng === 0) { return }
          if (!isNaN(lat) && !isNaN(lng))
            results.push({ serial, lat, lng, date, versaosw, versaofwpri, versaofwosc, located });
        });
      await Promise.all(pinss);

      points = results.map(item => ({
        located: item.located,
        type: "Feature",
        properties: {
          cluster: false,
          id: item.serial,
          data: item
        },
        geometry: {
          type: "Point",
          coordinates: [
            parseFloat(item.lng),
            parseFloat(item.lat)
          ]
        },
        address: item.address
      }));

      points = points.sort(function (a, b) {
        if (a.properties.data.serial > b.properties.data.stitleerial) {
          return 1;
        }
        if (a.properties.data.serial < b.properties.data.serial) {
          return -1;
        }
        return 0;
      });
      setState(points);

      const resultss = [];
      const pinsss = equipments.filter(i => i.address && i.address.city)
        .map(async (equipment) => {
          let lat = Number(parseFloat(equipment.lat).toFixed(7));
          let lng = Number(parseFloat(equipment.lng).toFixed(7));
          let date = dateformat(equipment.updatedAt);
          let serial = equipment.serialnumber;
          let versaosw = equipment.versaosw;
          let versaofwpri = equipment.versaofwpri;
          let versaofwosc = equipment.versaofwosc;
          let located = locatedList.includes(serial);

          if (equipment.address !== "" && equipment.address.city !== null) {
            lat = Number(parseFloat(equipment.address.city.lat).toFixed(7));
            lng = Number(parseFloat(equipment.address.city.lng).toFixed(7));
          }
          if (lat === 0 || lng === 0) { return }
          if (!isNaN(lat) && !isNaN(lng))
            resultss.push({ serial, lat, lng, date, versaosw, versaofwpri, versaofwosc, located });
        });
      await Promise.all(pinsss);

      points = resultss.map(item => ({
        located: item.located,
        type: "Feature",
        properties: {
          cluster: false,
          id: item.serial,
          data: item
        },
        geometry: {
          type: "Point",
          coordinates: [
            parseFloat(item.lng),
            parseFloat(item.lat)
          ]
        },
        address: item.address
      }));

      points = points.sort(function (a, b) {
        if (a.properties.data.serial > b.properties.data.stitleerial) {
          return 1;
        }
        if (a.properties.data.serial < b.properties.data.serial) {
          return -1;
        }
        return 0;
      });

      setCity(points);
    })();
  }, []);


  useEffect(() => {
    (async () => {
      const { data } = await ApiMServer.get('/company/v1/getcooperative');
      setCompanies(data);
      let filterCompany = data.filter((item) => {
        return item.typeCompany === true;
      })
      let cNames = [].concat.apply([], filterCompany.map((company) => {
        return company.name;
      }));
      setCompanyNames(cNames.sort());
    })();
  }, []);

  const filter = (f, company, serial) => {

    if (!f[7] && !f[8]) {
      setMapPoints(false);
    } else {
      setMapPoints(true);
    }

    let result;

    if (f[7]) {
      result = [...state];
    } else if (f[8]) {
      result = [...city];
    } else {
      result = [...points];
    }

    if (f[9]) {
      result = result.filter((item) => item?.properties?.data?.isConnected);
      setAllPoints(result);
      return;
    }

    if (serial) {
      result = result.filter((item) => {
        return item?.properties?.data?.serial?.toUpperCase().includes(serial.toUpperCase());
      });
      setAllPoints(result);
    }

    result = result.filter((item) => {
      if (company === '') return true;
      let c = companies.filter((i) => i.name === company).map((i) => {
        return i.equipments;
      })[0];
      if (c && c.includes(item.properties.data.serial)) {
        return true;
      } else {
        return false;
      }
    }).filter((item) => {
      if (f[0]) {
        return true;
      }
      if (f[1] && !f[2] && !f[3] && !f[4] && !f[5] && !f[6]) {
        if (item.located) {
          return true;
        }
      }

      if (f[2] && f[1]) {
        if (item.properties.data.serial.includes('SIMT') && item.located) {
          return true;
        }
      } else if (f[2]) {
        if (item.properties.data.serial.includes('SIMT')) {
          return true;
        }
      }

      if (f[3] && f[1]) {
        if (item.properties.data.serial.includes('BIMT') && item.located) {
          return true;
        }
      } else if (f[3]) {
        if (item.properties.data.serial.includes('BIMT')) {
          return true;
        }
      }

      if (f[4] && f[1]) {
        if (item.properties.data.serial.includes('CPMT') && item.located) {
          return true;
        }
      } else if (f[4]) {
        if (item.properties.data.serial.includes('CPMT')) {
          return true;
        }
      }

      if (f[5] && f[1]) {
        if (item.properties.data.serial.includes('EEMT') && item.located) {
          return true;
        }
      } else if (f[5]) {
        if (item.properties.data.serial.includes('EEMT')) {
          return true;
        }
      }

      if (f[6] && f[1]) {
        if (item.properties.data.serial.includes('ESMR') && item.located) {
          return true;
        }
      } else if (f[6]) {
        if (item.properties.data.serial.includes('ESMR')) {
          return true;
        }
      }

      if (f[10] && f[1]) {
        if (item.properties.data.serial.includes('SEMT') && item.located) {
          return true;
        }
      } else if (f[10]) {
        if (item.properties.data.serial.includes('SEMT')) {
          return true;
        }
      }

      if (f[7] && !f[0] && !f[1] && !f[2] && !f[3] && !f[4] && !f[5] && !f[6] && !f[9] && !f[10]) {
        setAllPoints(state)
        return true
      }

      if (f[8] && !f[0] && !f[1] && !f[2] && !f[3] && !f[4] && !f[5] && !f[6] && !f[9] && !f[10]) {
        setAllPoints(city)
        return true
      }
      return false;
    });
    setAllPoints(result);
  };

  return (
    <div style={{ width: '100%', height: '100vh', backgroundColor: '#292E29' }}>
      {loading && <Reload />}
      
      <Filter filter={filter} companyNames={companyNames} count={allPoints.length} />
      
      {points.length > 0 && (
        <Map
          center={center}
          containerElement={<div style={{ height: '100%' }} />}
          googleMapURL={`https://maps.googleapis.com/maps/api/js?v=3.exp&libraries=geometry,drawing,places&key=${GOOGLE_API_KEY}&language=pt-br`}
          loadingElement={<div style={{ height: '100%' }} />}
          mapElement={<div style={{ height: '100%' }} />}
          mapPoints={mapPoints}
          zoom={zoom}
          points={allPoints}
        />
      )}
    </div>
  );
};
export default Maps;
