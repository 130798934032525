import React, { useState } from 'react';
import { Page, NotifyModal, ModalAlert } from 'components';
import { Header, Form } from './components';
import { useHistory } from 'react-router-dom';

import ApiMServer from 'services/ApiMServer';

const UnitsAdd = () => {
  const history = useHistory();

  const user = JSON.parse(localStorage.getItem('userData'));
  if (user?.permission?.units?.write !== true) {
    window.location.replace('/home');
  }

  const { permission } = JSON.parse(localStorage.getItem('userData'));

  const [units, setUnits] = useState({
    name: '',
    cnpj: '',
    fone: '',
    email: '',
    ncontract: '',
    typeCompany: false
  });

  const [type, setType] = useState({ color: "", status: "" });
  const [modalShow, setModalShow] = useState(false);
  const [showModalAlert, setShowModalAlert] = useState(false);
  const [message, setMessage] = useState("");

  const handleModalAlert = (msg) => {
    setMessage(msg);
    setShowModalAlert(true)
  }

  const handleAlertModalSuccess = (msg) => {
    setType({ "color": "#198754", "status": msg })
    setModalShow(true)
  }

  const handleAlertModalError = (msg) => {
    setType({ "color": "red", "status": msg })
    setModalShow(true)
  }

  const handleFieldChange = (event, field, value) => {
    event.persist && event.persist();
    setUnits(values => ({
      ...values,
      [field]: value
    }));
  };

  const updateAddressAndLocation = (newAddress, newLat, newLng) => {
    setUnits(prevUnits => ({
      ...prevUnits,
      address: newAddress,
      location: {
        lat: String(newLat),
        lng: String(newLng)
      }
    }));
  };

  const handleSubmit = async () => {
    try {
      if (!units.name) {
        handleModalAlert('Preencha o Campo "Nome da Unidade"');
        return
      }

      if (!units.cnpj) {
        handleModalAlert('Preencha o campo "CNPJ"');
        return
      }

      if (!units.address) {
        handleModalAlert('Preencha o campo "Endereço"');
        return
      }

      if (!units.ncontract) {
        handleModalAlert('Preencha o campo "Número do contrato"');
        return
      }

      if (!units.fone) {
        handleModalAlert('Preencha o campo "Telefone"');
        return
      }

      if (!units.email) {
        handleModalAlert('Preencha o campo "E-mail"');
        return
      }
      
      const { data } = await ApiMServer.post('/company/create', units);
      
      handleAlertModalSuccess("Adicionado com sucesso!");
      setUnits({
        name: '',
        cnpj: '',
        fone: '',
        email: '',
        ncontract: '',
        typeCompany: false
      });

      setTimeout(() => {
        history.push(`/units/${data._id}`);
      }, 1200);

    } catch (e) {
      handleAlertModalError('Falha ao adicionar!');
    }
  };

  return (
    <Page className="p-3" title="Unidades">
      <Header />

      <ModalAlert
        message={message}
        showModalAlert={showModalAlert}
        onHide={() => setShowModalAlert(false)}
      />

      <NotifyModal
        type={type}
        show={modalShow}
        onHide={() => setModalShow(false)}
      />

      <Form
        permission={permission}
        fieldChange={handleFieldChange}
        onSubmit={handleSubmit}
        units={units}
        setLocation={updateAddressAndLocation}
      />

    </Page>
  );
};

export default UnitsAdd;
