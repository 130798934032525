/* eslint-disable react/no-multi-comp */
/* eslint-disable react/display-name */

import {
  FaTachometerAlt,
  FaMapMarkedAlt,
  FaBuilding,
  FaBriefcase,
  FaInbox,
  FaClipboard,
  FaUser,
  FaLock,
  FaCog,
  FaSignOutAlt,
  FaQuestionCircle,
  FaPaperPlane,
  FaChartBar,
  FaExchangeAlt
} from 'react-icons/fa';

export default [
  {
    pages: [
      {
        page: 'home',
        title: 'Home',
        href: '/home',
        icon: FaTachometerAlt
      },
      {
        page: 'users',
        title: 'Usuários',
        href: '/users',
        icon: FaUser
      },
      {
        page: 'cooperatives',
        title: 'Cooperativas',
        href: '/cooperatives',
        icon: FaBriefcase
      },
      {
        page: 'units',
        title: 'Unidades',
        href: '/units',
        icon: FaBuilding
      },
      {
        page: 'equipments',
        title: 'Equipamentos',
        href: '/equipments',
        icon: FaInbox
      },
      {
        page: 'equipmentsreplace',
        title: 'Substituir',
        href: '/equipments/replace',
        icon: FaExchangeAlt
      },
      {
        page: 'maps',
        title: 'Mapa',
        href: '/maps',
        icon: FaMapMarkedAlt
      },
      {
        page: 'mapsunits',
        title: 'Mapa Unidades',
        href: '/maps-units',
        icon: FaMapMarkedAlt
      },
      {
        page: 'charts',
        title: 'Curvas',
        href: '/charts',
        icon: FaChartBar
      },
      {
        page: 'support',
        title: 'Suporte',
        href: '/support',
        icon: FaQuestionCircle
      },
      {
        page: 'supportdry',
        title: 'Suporte Dry',
        href: '/supportdry',
        icon: FaQuestionCircle
      },
      {
        page: 'equipmentsQL',
        title: 'Qualidade',
        href: '/equipmentsQL',
        icon: FaClipboard
      },
      {
        page: 'notifications',
        title: 'Notificações',
        href: '/notifications',
        icon: FaPaperPlane
      },
      {
        page: 'userspassword',
        title: 'Gera Senha',
        href: '/userspassword',
        icon: FaLock
      },
      {
        page: 'general',
        title: 'Configurações',
        href: '/settings/general',
        icon: FaCog
      },
      {
        page: 'logout',
        title: 'Sair',
        href: '/logout',
        icon: FaSignOutAlt,
      }
    ]
  }
];
