import React, { useState } from 'react';
import { SearchLocationInput, BootstrapMultiSelect } from 'components';
import sjcl from 'sjcl';

import {
  Card,
  Row,
  Col,
  Form,
  FloatingLabel,
  Button,
  Badge,
} from 'react-bootstrap';

const Forms = (props) => {
  const {
    fieldChange,
    onSubmit,
    units,
    permission,
    setEmails,
    emails,
    setUnits,
    setLocation,
    allEmails,
  } = props;

  const handelHash = () => {
    if (!units.secretOnlyKey) {
      const myBitArray = sjcl.hash.sha256.hash(units._id);
      const myHash = sjcl.codec.hex.fromBits(myBitArray);
      units.secretOnlyKey = myHash
      setUnits({ ...units });
    }
  }

  const formatCNPJ = (value) => {
    value = value.replace(/\D/g, ''); // Remove tudo que não é número

    if (value.length > 14) {
      value = value.slice(0, 14); // Limita ao tamanho máximo do CNPJ
    }

    return value
      .replace(/^(\d{2})(\d)/, '$1.$2')
      .replace(/^(\d{2})\.(\d{3})(\d)/, '$1.$2.$3')
      .replace(/\.(\d{3})(\d)/, '.$1/$2')
      .replace(/(\d{4})(\d)/, '$1-$2');
  };

  const handleCNPJChange = (event) => {
    const formattedCNPJ = formatCNPJ(event.target.value);
    fieldChange(event, 'cnpj', formattedCNPJ);
  };

  const formatPhone = (value) => {
    // Remove todos os caracteres que não são números
    value = value.replace(/\D/g, '');

    // Limita o número de caracteres para o formato de telefone brasileiro
    if (value.length > 11) {
      value = value.slice(0, 11);
    }

    // Formata o telefone com o padrão (XX) XXXX-XXXX ou (XX) XXXXX-XXXX
    if (value.length <= 10) {
      return value.replace(/^(\d{2})(\d{4})(\d{4})$/, '($1) $2-$3');
    } else {
      return value.replace(/^(\d{2})(\d{5})(\d{4})$/, '($1) $2-$3');
    }
  };

  const handlePhoneChange = (event) => {
    const formattedPhone = formatPhone(event.target.value);
    fieldChange(event, 'fone', formattedPhone);
  };

  const [inputValue, setInputValue] = useState('');

  const isEmailValid = (email) => {
    // Validação simples de e-mail
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter' || e.key === ',') {
      e.preventDefault();
      if (isEmailValid(inputValue) && !emails.includes(inputValue)) {
        setEmails([...emails, inputValue]);
        setInputValue('');
      }
    }
  };

  const removeEmail = (index) => {
    setEmails(emails.filter((_, i) => i !== index));
  };

  return (
    <Card className='mb-3 mt-3'>
      <Card.Header>
        <h5 className='mt-2 mb-2'>Dados da Cooperativa</h5>
      </Card.Header>
      <Card.Body>
        <Form>
          <Row className="g-2">

            <Col md>
              <FloatingLabel controlId="floatingInput" label="Nome" className="mb-3">
                <Form.Control
                  type="text"
                  name="Nome"
                  placeholder="Nome"
                  onChange={event =>
                    fieldChange(event, 'name', event.target.value)
                  }
                  value={units.name}
                  disabled={!permission}
                />
              </FloatingLabel>
            </Col>

            <Col md>
              <FloatingLabel controlId="floatingInput" label="CNPJ" className="mb-3">
                <Form.Control
                  type="text"
                  name="CNPJ"
                  placeholder="CNPJ"
                  onChange={handleCNPJChange}
                  value={units.cnpj}
                  disabled={!permission}
                />
              </FloatingLabel>
            </Col>

            <Col md>
              <FloatingLabel controlId="floatingInput" label="Telefone" className="mb-3">
                <Form.Control
                  type="text"
                  name="Telefone"
                  placeholder="Telefone"
                  onChange={handlePhoneChange}
                  value={units.fone}
                  disabled={!permission}
                />
              </FloatingLabel>
            </Col>

          </Row>

          <Row className="g-2">
            <Col md>
              <SearchLocationInput
                setLocation={setLocation}
                addr={units.address}
              />
            </Col>

            <Col md>
              <FloatingLabel controlId="floatingInput" label="Número do Contrato de Locação" className="mb-3">
                <Form.Control
                  type="text"
                  name="Número do Contrato de Locação"
                  placeholder="Número do Contrato de Locação"
                  onChange={event =>
                    fieldChange(event, 'ncontract', event.target.value)
                  }
                  value={units.ncontract}
                  disabled={!permission}
                />
              </FloatingLabel>
            </Col>

            <Col md>
              <FloatingLabel controlId="floatingInput" label="E-mail" className="mb-3">
                <Form.Control
                  type="email"
                  name="E-mail"
                  placeholder="E-mail"
                  onChange={event =>
                    fieldChange(event, 'email', event.target.value)
                  }
                  value={units.email}
                  disabled={!permission}
                />
              </FloatingLabel>
            </Col>
          </Row>

          <Row className="g-2">
            <Col md>
              <FloatingLabel controlId="floatingInput" label="Domínio de E-mail da empresa" className="mb-3">
                <Form.Control
                  type="text"
                  name="Domínio de E-mail da empresa"
                  placeholder="Domínio de E-mail da empresa"
                  onChange={event =>
                    fieldChange(event, 'dominioEmail', event.target.value.trim())
                  }
                  value={units?.dominioEmail}
                  disabled={!permission}
                />
              </FloatingLabel>
            </Col>

            <Col md>
              <FloatingLabel controlId="floatingInput" label="Chave única API externa" className="mb-3">
                <Form.Control
                  type="text"
                  name="Chave única API externa"
                  placeholder="Chave única API externa"
                  value={units.secretOnlyKey}
                  disabled
                />
              </FloatingLabel>
            </Col>
          </Row>

          <Row className="g-2">
            <Col md={6} >
              <BootstrapMultiSelect
                hiddenOptions={true}
                label={"E-mails para acesso ao SGU"}
                options={allEmails}
                selectedEquipments={emails}
                setSelectedEquipments={setEmails}
              />
            </Col>

            <Col md={6}>
              {emails.map((email, index) => (
                <Badge key={index} pill bg="success" className="me-2">
                  {email}{' '}
                  <span
                    style={{ cursor: 'pointer', marginLeft: 5, padding: 5 }}
                    onClick={() => removeEmail(index)}
                  >
                    ×
                  </span>
                </Badge>
              ))}
            </Col>
          </Row>

          <Row className="align-items-center mt-3">
            <Col xs={12} md="auto">
              <Form.Check
                isValid
                type="checkbox"
                label="Acesso API externa"
                disabled={!permission}
                checked={units.accessSecretOnlyKey}
                onChange={event => {
                  handelHash();
                  fieldChange(event, 'accessSecretOnlyKey', !units.accessSecretOnlyKey);
                }}
              />
            </Col>
            <Col xs={12} md="auto">
              <Form.Check
                isValid
                type="checkbox"
                label="Restringir ativação a usuários da empresa"
                disabled={!permission}
                checked={units.restrictAcess}
                onChange={event =>
                  fieldChange(event, 'restrictAcess', !units.restrictAcess)
                }
              />
            </Col>
          </Row>

        </Form>

        <Row className='g-2'>
          <Col xs="auto">
            <Button
              className='mt-3'
              disabled={!permission}
              onClick={() => onSubmit()}
              variant="success"
            >
              Salvar
            </Button>
          </Col>
        </Row>

      </Card.Body>
    </Card>
  )
}
export default Forms;
