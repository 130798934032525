import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { getToken } from 'actions/sessionActions';
import { Page } from 'components';
import { Forms, Results } from './components';
import { Card, Col, Row, Button, Modal } from 'react-bootstrap';
import Header from '../../components/Header/Header';
import Reload from '../../components/Reload/Reload';
import ApiGSenha from 'services/ApiGSenha';
import ApiMServer from 'services/ApiMServer';
import NotifyModal from '../../components/NotifyModal/NotifyModal';

const UsersDetail = (props) => {
  const history = useHistory();

  const [valueForm, setValueForm] = useState(false)
  const [valueFormSenhas, setValueFormSenhas] = useState(false)
  const data = JSON.parse(localStorage.getItem('userData'));

  if (data?.permission?.users?.read !== true) {
    window.location.replace('/home');
  }

  const userId = data._id;
  const { permission } = data;

  const [user, setUser] = useState({
    equipments: [],
    _id: '',
    name: '',
    username: '',
    email: '',
    birth: '',
    password: '',
    master: '',
    permissionSGU: {
      home: {
        read: '',
        write: '',
      },
      equipments: {
        read: '',
        write: '',
      },
      units: {
        read: '',
        write: '',
      },
      restricted: {
        read: '',
        write: '',
      },
      report: {
        read: '',
        write: '',
      },
      settings: {
        read: '',
        write: '',
      },
      companyData: {
        read: '',
        write: ''
      },
    },

    permission: {
      home: {
        read: '',
        write: ''
      },
      users: {
        read: '',
        write: ''
      },
      units: {
        read: '',
        write: ''
      },
      cooperatives: {
        read: '',
        write: ''
      },
      equipments: {
        read: '',
        write: ''
      },
      maps: {
        read: '',
        write: ''
      },
      mapsunits: {
        read: '',
        write: ''
      },
      charts: {
        read: '',
        write: ''
      },
      equipmentsreplace: {
        read: '',
        write: ''
      },
      support: {
        read: '',
        write: ''
      },
      supportdry: {
        read: '',
        write: ''
      },
      equipmentsQL: {
        read: '',
        write: ''
      },
      notifications: {
        read: '',
        write: ''
      },
      userspassword: {
        read: '',
        write: ''
      },
      general: {
        read: '',
        write: ''
      }
    },
    fone: '',
    company: '',
    cpfcnpj: '',
    validationHash: '',
    createdAt: '',
    updatedAt: '',
    __v: 0,
    token: '',
    firebaseToken: '',
    validationEmail: Boolean,
    oldName: null,
    blocked: null
  });

  const [open, setOpen] = useState(false);
  const [openAuth, setOpenAuth] = useState(false);
  const [openAuthenticate, setOpenAuthenticate] = useState(false);
  const [openAuthPassword, setOpenAuthPassword] = useState(false);
  const [openBlockUser, setOpenBlockUser] = useState(false);
  const [openUnblockFone, setOpenUnblockFone] = useState(false);
  const [activactions, setActivactions] = useState([]);
  const [pass, setPass] = useState([]);
  const [loading, setLoading] = useState(true);

  const [type, setType] = useState({ color: "", status: "" });
  const [modalShow, setModalShow] = useState(false);

  const handleAlertModalSuccess = (msg) => {
    setType({ "color": "#198754", "status": msg })
    setModalShow(true)
  }

  const handleAlertModalError = (msg) => {
    setType({ "color": "red", "status": msg })
    setModalShow(true)
  }

  const removeUser = async () => {
    handleClose();
    try {
      await ApiMServer.delete(`/users/${user._id}`, { headers: { token: getToken() } })
      history.push('/users');
    } catch (error) {
      handleAlertModalError('Erro ao apagar usuário!');
    }
  };

  const authUser = async () => {
    handleClose();
    try {
      await ApiMServer.post('/validateemailforced', { _id: user._id, token: getToken() });
      handleAlertModalSuccess('E-mail Validado!');
    } catch (error) {
      handleAlertModalError('Erro ao validar E-mail!');
    }
  };

  const authenticateUser = async () => {
    handleClose();
    try {
      await ApiMServer.post('/authenticateuser', { id: user._id, token: getToken() })
      handleAlertModalSuccess('Usuário autenticado!');
    } catch (error) {
      handleAlertModalError('Erro ao autenticar usuário!');
    }
  };

  const unblockFone = async () => {
    handleClose();
    try {
      await ApiMServer.post('/user/clearexclusiveaccess', { email: user.email, token: getToken() })
      handleAlertModalSuccess('Telefone desbloqueado!');
    } catch (error) {
      handleAlertModalError('Erro ao desbloquear!');
    }
  };

  const authUserPassword = async () => {
    handleClose();
    try {
      let resp = await ApiMServer.post('/passwordgeneratepermission',
        {
          email: data?.email,
          password: data?.password,
          id: user?._id,
          token: data?.token
        }
      )
      handleAlertModalSuccess(resp.data.msg);
    } catch (error) {
      handleAlertModalError('Erro ao atualizar!');
    }
  };

  const handleClose = () => {
    setOpen(false);
    setOpenAuth(false);
    setOpenAuthPassword(false);
    setOpenAuthenticate(false);
    setOpenAuthPassword(false);
    setOpenBlockUser(false);
    setOpenUnblockFone(false);
  };

  const getActivations = async (email) => {
    try {
      let { data } = await ApiGSenha.post('/log/unlockbyuser', { username: email, token: getToken() });
      setActivactions(data);
    } catch (error) {
      console.log(error);
      alert('Erro:', error.message);
    }
  };

  const getPass = async (email) => {
    try {
      let { data } = await ApiGSenha.post('/log/passuser', { username: email, params: 50, token: getToken() });
      setPass(data);
    } catch (error) {
      console.log(error);
      alert('Erro:', error.message);
    }
  };

  useEffect(() => {
    (async () => {
      const { id } = props.match.params;

      try {
        const { data } = await ApiMServer.get(`/users/${id}`);

        const defaultPermissionsSGU = {
          home: { read: false, write: false },
          equipments: { read: false, write: false },
          units: { read: false, write: false },
          restricted: { read: false, write: false },
          cooperatives: { read: false, write: false },
          report: { read: false, write: false },
          settings: { read: false, write: false },
          companyData: { read: false, write: false }
        };

        const defaultPermissions = {
          home: { read: false, write: false },
          users: { read: false, write: false },
          units: { read: false, write: false },
          cooperatives: { read: false, write: false },
          equipments: { read: false, write: false },
          maps: { read: false, write: false },
          mapsunits: { read: false, write: false },
          charts: { read: false, write: false },
          equipmentsreplace: { read: false, write: false },
          support: { read: false, write: false },
          supportdry: { read: false, write: false },
          equipmentsQL: { read: false, write: false },
          notifications: { read: false, write: false },
          userspassword: { read: false, write: false },
          general: { read: false, write: false }
        };

        // Adiciona as permissões caso não existam
        data.permissionSGU = { ...defaultPermissionsSGU, ...data.permissionSGU };
        data.permission = { ...defaultPermissions, ...data.permission };

        setUser(data);
        await Promise.all([getActivations(data.email), getPass(data.email)]);
      } catch (error) {
        console.error("Erro ao carregar usuário:", error);
        alert(error.message);
      }

      setLoading(false);
    })();
  }, []);


  const fieldChanged = (event, field, value) => {
    event.persist && event.persist();
    setUser((values) => ({
      ...values,
      [field]: value
    }))
  }

  const cpfMask = (value) => {
    return value
      .replace(/\D/g, '')
      .replace(/(\d{3})(\d)/, '$1.$2')
      .replace(/(\d{3})(\d)/, '$1.$2')
      .replace(/(\d{3})(\d{1,2})/, '$1-$2')
      .replace(/(-\d{2})\d+?$/, '$1');
  };

  const phoneMask = (value) => {
    return value
      .replace(/\D/g, '')
      .replace(/(\d{2})(\d)/, '($1)$2')
      .replace(/(\d{5})(\d)/, '$1-$2')
      .replace(/(-\d{4})\d+?$/, '$1');
  };

  const cpfChanged = (event, field, value) => {
    event.persist && event.persist();
    setUser((values) => ({
      ...values,
      [field]: cpfMask(value)
    }));
  };

  const phoneChanged = (event, field, value) => {
    event.persist && event.persist();
    setUser((values) => ({
      ...values,
      [field]: phoneMask(value)
    }));
  };

  const saveUser = async () => {
    try {
      user.userId = userId;
      await ApiMServer.put('/user', user, { headers: { token: getToken() } });
      handleAlertModalSuccess('Atualizado com sucesso!');
    } catch (error) {
      if (error?.response?.data?.status) {
        handleAlertModalError(error?.response?.data?.status);
      } else {
        handleAlertModalError("Erro ao atualizar, faça login novamente!");
      }
    }
  };

  const forgotPassword = async () => {
    handleClose();
    try {
      await ApiMServer.post('/forgotpassword', { email: user.email, token: getToken() })
      handleAlertModalSuccess('Enviado com sucesso!');
    } catch (error) {
      handleAlertModalError("Erro ao enviar!");
    }
  };

  const handleBlockUser = () => {
    handleClose();
    try {
      ApiMServer.post('/blockuser', { id: user._id, block: !user.blocked, "token": getToken() });
      setUser((values) => ({
        ...values,
        ['blocked']: !user.blocked
      }));
      handleAlertModalSuccess('Atualizado com sucesso!');
    } catch (error) {
      handleAlertModalError("Erro ao atualizar!");
    }
  };

  return (
    <Page className="p-3" title="Usuários">

      <Header page={'Usuários'} />

      <NotifyModal
        type={type}
        show={modalShow}
        onHide={() => setModalShow(false)}
      />

      <Card className='mb-2 mt-2 p-2'>
        <Row>
          <Col>
            <Button
              className='ms-2 mt-2 mb-2'
              onClick={() => { setOpenAuthenticate(true) }}
              disabled={!permission?.users?.write}
              variant="outline-success"
            >
              Autenticar Usuário
            </Button>

            <Button
              className='ms-2 mt-2 mb-2'
              onClick={() => { setOpenAuth(true) }}
              disabled={!permission?.users?.write}
              variant="outline-success"
            >
              Validar E-mail
            </Button>

            <Button
              className='ms-2 mt-2 mb-2'
              onClick={forgotPassword}
              disabled={!permission?.users?.write}
              variant="outline-success"
            >
              Enviar E-mail Recuperação de Senha
            </Button>

            <Button
              className='ms-2 mt-2 mb-2'
              onClick={() => setOpenAuthPassword(true)}
              disabled={!permission?.users?.write}
              variant="outline-success"
            >
              Autorizar Gera Senha
            </Button>

            <Button
              className='ms-2 mt-2 mb-2'
              disabled={!permission?.users?.write}
              onClick={() => setOpenUnblockFone(true)}
              variant="outline-success"
            >
              Desbloquear Telefone
            </Button>

            <Button
              className='ms-2 mt-2 mb-2'
              onClick={() => { setOpenBlockUser(true) }}
              disabled={!permission?.users?.write}
              variant="outline-success"
            >
              {user?.blocked ? 'Desbloquear Usuário' : 'Bloquear Usuário'}
            </Button>

            <Button
              className='ms-2 mt-2 mb-2'
              disabled={!permission?.users?.write}
              onClick={() => setOpen(true)}
              variant="outline-danger"
            >
              Remover
            </Button>
          </Col>
        </Row>
      </Card>

      {loading && <Reload />}

      <Forms
        fieldChanged={fieldChanged}
        cpfChanged={cpfChanged}
        phoneChanged={phoneChanged}
        saveUser={saveUser}
        permission={permission}
        user={user}
        setUser={setUser}
        setValueForm={setValueForm}
        setValueFormSenhas={setValueFormSenhas}
      />

      {!valueForm ? null :
        <Results title={'Ativações'} data={activactions} />
      }

      {!valueFormSenhas ? null :
        <Results title={'Senhas Geradas'} data={pass} />
      }

      {/* Desbloquer telefone */}
      <Modal show={openUnblockFone} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Desbloquear</Modal.Title>
        </Modal.Header>
        <Modal.Body>Confirma Desbloquear Telefone?</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Não
          </Button>
          <Button variant="danger" onClick={unblockFone}>
            Sim
          </Button>
        </Modal.Footer>
      </Modal>

      {/* Remover Usuário */}
      <Modal show={open} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Remover</Modal.Title>
        </Modal.Header>
        <Modal.Body>Confirma Remover este Usuário?</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Não
          </Button>
          <Button variant="danger" onClick={removeUser}>
            Sim
          </Button>
        </Modal.Footer>
      </Modal>

      {/* Autorizar Usuário */}
      <Modal show={openAuth} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Autorizar</Modal.Title>
        </Modal.Header>
        <Modal.Body>Autorizar E-mail desse Usuário?</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Não
          </Button>
          <Button variant="success" onClick={authUser}>
            Sim
          </Button>
        </Modal.Footer>
      </Modal>

      {/* Autenticar Cadastro */}
      <Modal
        show={openAuthenticate}
        onHide={handleClose}
      >
        <Modal.Header closeButton>
          <Modal.Title>Autenticar Cadastro</Modal.Title>
        </Modal.Header>
        <Modal.Body>Validar o Cadastro Deste Usuário?</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Não
          </Button>
          <Button variant="success" onClick={authenticateUser}>
            Sim
          </Button>
        </Modal.Footer>
      </Modal>

      {/* Autorizar Gerar Senha */}
      <Modal
        show={openAuthPassword}
        onHide={handleClose}
      >
        <Modal.Header closeButton>
          <Modal.Title>Autorizar Gerar Senha</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Autorizar Usuário a Gerar Senhas para o Equipamento Motomco?
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Não
          </Button>
          <Button variant="success" onClick={authUserPassword}>
            Sim
          </Button>
        </Modal.Footer>
      </Modal>

      {/* Bloquear/Desbloquear Usuário */}
      <Modal
        show={openBlockUser}
        onHide={handleClose}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            {user?.blocked ? 'Desbloquear Usuário' : 'Bloquear Usuário'}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Deseja {user?.blocked ? 'Desbloquear' : 'Bloquear'} este Usuário Para
          Gerar Ativações ao Equipamento Motomco?
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Não
          </Button>
          <Button variant={user?.blocked ? 'success' : 'danger'} onClick={handleBlockUser}>
            Sim
          </Button>
        </Modal.Footer>
      </Modal>

    </Page>
  );
};

export default UsersDetail;
