import React, { useState, useEffect } from 'react';
import { Form, Row, Col, Modal, Button } from "react-bootstrap"
import axios from 'utils/axios';
import { getToken } from 'actions/sessionActions';
import { Page, SearchBar } from 'components';
import { Results } from './components';
import Header from '../../components/Header/Header';
import Reload from '../../components/Reload/Reload';
import ApiGServer from 'services/ApiGServer';

const Equipments = (props) => {
  const user = JSON.parse(localStorage.getItem('userData'));
  if (user?.permission?.equipments?.read !== true) {
    window.location.replace('/home');
  }

  const [equipments, setEquipments] = useState([]);
  const [equipmentsToShow, setEquipmentsToshow] = useState([]);
  const [open, setOpen] = React.useState(false);
  const [searchData, setSearchData] = useState('');
  const [equipmentId, setEquipmentId] = useState('');
  const [permission, setPermission] = useState(false);
  const [loading, setLoading] = useState(true);
  const [checkOwner, setCheckOwner] = useState(false);
  const [checkLocation, setCheckLocation] = useState(false);

  const [stateWifi, setStateWifi] = useState(0);
  const [statePh, setStatePh] = useState(0);

  const handleStatateWifi = () => {
    setStateWifi(stateWifi === 0 ? 1 : stateWifi === 1 ? 2 : 0)
    handleSearch(checkOwner, checkLocation, stateWifi === 0 ? 1 : stateWifi === 1 ? 2 : 0, statePh);
  };

  const handleStatatePh = () => {
    setStatePh(statePh === 0 ? 1 : statePh === 1 ? 2 : 0)
    handleSearch(checkOwner, checkLocation, stateWifi, statePh === 0 ? 1 : statePh === 1 ? 2 : 0);
  };

  const handleClickOpen = (id) => {
    setEquipmentId(id);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleChangeProprietari = () => {
    if (checkOwner === false) {
      setCheckLocation(false);
    }
    setCheckOwner(!checkOwner);
    handleSearch(!checkOwner, false, stateWifi, statePh);
  }

  const handleChangeLocate = () => {
    if (checkLocation === false) {
      setCheckOwner(false);
    }
    setCheckLocation(!checkLocation);
    handleSearch(false, !checkLocation, stateWifi, statePh);
  }

  const getEquipments = async () => {
    setLoading(true);
    try {
      const { data } = await ApiGServer.get(`/equipment/all`);
      const sortedData = data.sort((a, b) => a.serialnumber.localeCompare(b.serialnumber));
      setEquipments(sortedData);
      setEquipmentsToshow(sortedData);
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };

  const removeEquipment = async () => {
    try {
      setOpen(false);
      await axios(process.env.REACT_APP_API_MOTOMCO, getToken()).post('/equipment/delete', { "_id": equipmentId });
      getEquipments();
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    const { permission } = JSON.parse(localStorage.getItem('userData'));
    setPermission(permission?.equipments)
    getEquipments();
  }, [])

  const handleSearch = (f0, f1, f2, f3) => {
    if (f0 == null) {
      f0 = checkOwner;
    }
    if (f1 == null) {
      f1 = checkLocation;
    }
    if (f2 == null) {
      f2 = stateWifi;
    }
    if (f3 == null) {
      f3 = statePh;
    }

    if (searchData === '' && f0 === false && f1 === false && f2 === 0 && f3 === 0) {
      setEquipmentsToshow(equipments);
    } else {

      var equipmentsSearch = [...equipments];

      if (searchData !== '') {
        equipmentsSearch = equipmentsSearch.filter(item =>
          item.serialnumber.toLowerCase().includes(searchData.toLowerCase())
        );
      }

      if (f0) {
        equipmentsSearch = equipmentsSearch.filter(item =>
          item.mode === "OWNER"
        );
      }

      if (f1) {
        equipmentsSearch = equipmentsSearch.filter(item =>
          item.mode === 'RENTAL'
        );
      }

      if (f2 === 1) {
        equipmentsSearch = equipmentsSearch.filter(v => v.wf === "OK")
      } else if (f2 === 2) {
        equipmentsSearch = equipmentsSearch.filter(v => v.wf !== "OK")
      }

      if (f3 === 1) {
        equipmentsSearch = equipmentsSearch.filter(v => v.calph === "OK")
      } else if (f3 === 2) {
        equipmentsSearch = equipmentsSearch.filter(v => v.calph !== "OK")
      }

      setEquipmentsToshow(equipmentsSearch);
    }
  };

  const handleSearchData = (e) => {
    setSearchData(e.target.value);
  };

  return (
    <Page className="p-3" title="Equipamentos">
      <Header page={'Equipamentos'} />

      {loading && <Reload />}

      <SearchBar
        onSearch={() => handleSearch(checkOwner, checkLocation, stateWifi, statePh)}
        onSearchData={handleSearchData}
        searchData={searchData}
      />

      <Row className="align-items-center mt-3 mb-2">
        <Col xs="auto">
          <Form.Check type="checkbox" inline>
            <Form.Check.Input
              type="checkbox"
              onChange={handleChangeProprietari}
              isValid
              checked={checkOwner}
            />
            <Form.Check.Label>Proprietário</Form.Check.Label>
          </Form.Check>
        </Col>

        <Col xs="auto">
          <Form.Check type="checkbox" inline>
            <Form.Check.Input
              type="checkbox"
              onChange={handleChangeLocate}
              isValid
              checked={checkLocation}
            />
            <Form.Check.Label>Locação</Form.Check.Label>
          </Form.Check>
        </Col>

        <Col xs="auto">
          <Form.Check type="checkbox" inline>
            <Form.Check.Input
              type="checkbox"
              onChange={handleStatateWifi}
              isValid
              checked={stateWifi === 1 ? true : stateWifi === 2 ? false : undefined}
            />
            <Form.Check.Label>Wi-Fi</Form.Check.Label>
          </Form.Check>
        </Col>

        <Col xs="auto">
          <Form.Check type="checkbox" inline>
            <Form.Check.Input
              type="checkbox"
              onChange={handleStatatePh}
              isValid
              checked={statePh === 1 ? true : statePh === 2 ? false : undefined}
            />
            <Form.Check.Label>PH Calibrado</Form.Check.Label>
          </Form.Check>
        </Col>
      </Row>

      {equipments && (
        <Results
          data={equipmentsToShow}
          permission={permission}
          onRemoveButton={handleClickOpen}
        />
      )}

      <Modal
        show={open}
        onHide={handleClose}
      >
        <Modal.Header closeButton>
          <Modal.Title id="alert-dialog-title">Remover</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Confirma Remover este Equipamento?
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Não
          </Button>
          <Button variant="danger" onClick={removeEquipment}>
            Sim
          </Button>
        </Modal.Footer>
      </Modal>

    </Page >
  );
};

export default Equipments;
