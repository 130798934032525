import React, { useState, useRef } from 'react';
import useSupercluster from "use-supercluster";
import GoogleMapReact from "google-map-react";
import { Marker } from 'react-google-maps';
import InfoWindow from '../InfoWindow';
import './Map.css';

const Map = ({ points }) => {
  const mapRef = useRef();

  const [bounds, setBounds] = useState(null)
  const [zoom, setZoom] = useState(5)

  const [center, setCenter] = useState({
    lat: -14.4284,
    lng: -55.2733
  });

  const handleCloseInfoWindown = () => {
    setSelectedUnit(null);
    setCenter({
      lat: -14.4284,
      lng: -55.2733
    });
    setZoom(5);
  }

  const { clusters, supercluster } = useSupercluster({
    points,
    bounds,
    zoom,
    options: { radius: 30, maxZoom: 15 }
  });

  const [selectedUnit, setSelectedUnit] = useState(null);

  return (
    <div style={{ height: '100%', width: '100%' }}>

      <GoogleMapReact
        bootstrapURLKeys={{ key: process.env.REACT_APP_GOOGLE_API_KEY }}
        defaultCenter={{
          lat: -14.4284,
          lng: -55.2733
        }}
        center={center}
        zoom={zoom}
        defaultZoom={4}
        yesIWantToUseGoogleMapApiInternals
        onGoogleApiLoaded={({ map }) => {
          mapRef.current = map;
        }}
        onChange={({ zoom, bounds }) => {
          setZoom(zoom);
          setBounds([
            bounds.nw.lng,
            bounds.se.lat,
            bounds.se.lng,
            bounds.nw.lat
          ]);
        }}>

        {clusters.map(marker => {
          const [longitude, latitude] = marker.geometry.coordinates;
          const {
            cluster: isCluster,
            point_count: pointCount
          } = marker.properties;

          if (isCluster) {
            return (
              <Marker
                key={marker.id || `marker-${latitude}-${longitude}`}
                onClick={() => {
                  setSelectedUnit(marker);
                }}
                lat={latitude}
                lng={longitude}
              >
                <div
                  className="cluster"
                  style={{
                    "width": `${15 + (pointCount / points.length) * 30}px`,
                    "height": `${15 + (pointCount / points.length) * 30}px`,
                    "zIndex": 9999
                  }}
                  onClick={() => {
                    const expansionZoom = Math.min(
                      supercluster.getClusterExpansionZoom(marker.id),
                      20
                    );
                    mapRef.current.setZoom(expansionZoom);
                    mapRef.current.panTo({ lat: latitude, lng: longitude });
                  }}
                >
                  {pointCount}
                </div>
              </Marker>
            )
          } else {
            return (
              <Marker key={marker.id || `marker-${latitude}-${longitude}`} lat={latitude} lng={longitude}>
                <div className="marker-container">
                  <span className="marker-label">{marker.properties.name}</span>
                  <img
                    alt={marker.properties.name}
                    height={45}
                    className="marker"
                    src={'./images/ic_silo.png'}
                    onClick={() => setSelectedUnit(marker.properties.data)}
                  />
                </div>
              </Marker>
            )
          }
        })}

        {selectedUnit &&
          <InfoWindow
            close={handleCloseInfoWindown}
            data={selectedUnit}
            lat={selectedUnit.lat}
            lng={selectedUnit.lng}
            setCenter={setCenter}
          />
        }

      </GoogleMapReact>
    </div>
  )
}
export default Map;
