import React from 'react';
import { InputGroup, FormControl } from 'react-bootstrap';
import { FaSearch } from 'react-icons/fa';

const SearchBar = ({ onSearch, onSearchData, searchData }) => {
  return (
    <InputGroup className="mt-2 mb-2 border border-success rounded" style={{ width: 250 }}>
      <FormControl
        placeholder="Buscar..."
        aria-label="Buscar..."
        onChange={onSearchData}
        value={searchData}
        onKeyUp={onSearch}
        style={{ border: 'none' }}
      />
      <InputGroup.Text>
        <FaSearch />
      </InputGroup.Text>
    </InputGroup>
  );
};

export default SearchBar;
