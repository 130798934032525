import React, { useEffect } from 'react';
import Modal from 'react-bootstrap/Modal';

const NotifyModal = (props) => {
  const { type, onHide } = props;

  useEffect(() => {
    const timer = setTimeout(() => {
      onHide();
    }, 1500);
    return () => clearTimeout(timer);
  }, [onHide]);

  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Body style={{ "background": type.color, "borderRadius": "5px", "color": "#f4f4f4" }}>
        <h5 style={{ "margin": "0px", "textAlign": "center" }}>{type.status}</h5>
      </Modal.Body>
    </Modal>
  );
};

export default NotifyModal;
