import React, { useState, useEffect } from 'react';

import axios from 'utils/axios';
import { getToken } from 'actions/sessionActions';

import { Page } from 'components';
import { Header, Form } from './components';
import 'chartjs-plugin-zoom';
import { makeStyles } from '@material-ui/styles';

import dateformat from 'utils/dateformat';

import CircularProgress from '@material-ui/core/CircularProgress';

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: '100%',
    margin: '0 auto',
    padding: theme.spacing(3, 3, 6, 3)
  },
  aboutProject: {
    marginTop: theme.spacing(2)
  }
}));

const EquipmentsDetailQL = (props) => {
  const classes = useStyles();

  const [allData, setAllData] = useState({});
  const [data, setData] = useState({});
  const [selectedId, setSelectedId] = useState('');
  let [loading, setLoading] = useState(true);

  const user = JSON.parse(localStorage.getItem('userData'));
  if (user?.permission?.equipmentsQL?.read !== true) {
    window.location.replace('/home');
  }

  useEffect(() => {
    (async () => {
      // eslint-disable-next-line react/prop-types
      const { serial } = props.match.params;
      await axios(process.env.REACT_APP_API_QUALIDADE, getToken())
        .get(`/measurements`, {
          headers: {
            serialnumber: serial
          }
        })
        .then((response) => {
          setLoading(false);
          var data = response.data;
          var arrayIds = data.medicoes
            .filter((item) => {
              return item.testId;
            })
            .map((item) => {
              return item.testId;
            });

          arrayIds = Array.from(new Set(arrayIds));

          data.testIds = arrayIds;
          const allData = { ...data };
          setAllData(allData);
          let id = data.testIds ? data.testIds[data.testIds.length - 1] : '';
          filterData(data, id);
        });
    })();
  }, []);

  const filterData = (data, id) => {
    data.errors = data.errors.filter((item) => {
      return item.testId === id;
    });
    data.medicoes = data.medicoes.filter((item) => {
      return item.testId === id;
    });

    var values = Array();
    data.errors.forEach((item) => {
      if (item.value) {
        let arr = item.value.split(';').filter((i) => i !== '');
        arr.forEach((v) => {
          values.push(v);
        });
      }
    });

    var count = {};
    values.forEach(function (i) {
      count[i] = (count[i] || 0) + 1;
    });

    data.formattedErrors = Object.entries(count).map((item) => {
      item[0] = getErrorDescription(parseInt(item[0]));
      return item;
    });

    setSelectedId(id);
    processData(data, id);
  };

  const getErrorDescription = (i) => {
    let errors = [
      'Não fechou a tampa do funil no início',
      'Sensor IR direito não detectou amostra',
      'Sensor IR esquerdo não detectou amostra',
      'Não abriu a tampa do funil para começar o processo',
      'Não fechou a tampa do funil depois que começou o processo',
      'Amostra é insuficiente para medir',
      'Borboleta nao fechou',
      'Ativou automaticamente por tempo',
      'Copo de medição não estava na posição correta',
      'Borboleta não abriu completamente',
      'Temperatura Lida fora do valor esperado',
      'CAPX fora do valor esperado',
      'DIAL antes de corrigir fora do valor esperado',
      'Não conseguiu corrigir o DIAL pelo peso',
      'Ocorreu um erro ao corrigir o DIAL',
      'Umidade ficou fora da faixa de medição',
      'Erro inesperado ao calcular a umidade',
      'Frequencia da osciladora vazia fora do esperado',
      'Falha na célula de carga',
      'Servo motor nao atuou',
      'Dial calculado menor que o mínimo',
      'Dial calculado maior que o máximo',
      'Não encontrou a gaveta',
      'Copo de medição desconectado',
      'Falha no sensor de temperatura'
    ];

    let err = errors[i] ? errors[i] : 'Não Mapeado';
    if (err === 'Não Mapeado') {
      console.log('Não Mapeado: ', i);
    }
    return err;
  };

  const average = (arr) =>
    (
      arr.reduce((a, b) => parseFloat(a) + parseFloat(b), 0) / arr.length
    ).toFixed(2);

  const processData = (data) => {
    const { serial } = props.match.params;
    let listErrorsCode = [];

    data.numerrors = data.errors.length;
    data.nummedicoes = data.medicoes.length;

    data.errors.forEach((item) => {
      let temp = item.value.split(';');
      temp.forEach((element) => {
        listErrorsCode.push(element);
      });
    });

    data.errosCelCarga = listErrorsCode.filter((x) => x === '18').length;
    data.errosCelCargaPercent = (
      (data.errosCelCarga * 100) /
      (data.numerrors + data.nummedicoes)
    ).toFixed(2);
    data.errosCupMed = listErrorsCode.filter((x) => x === '8').length;
    data.errosCupMedPercent = (
      (data.errosCupMed * 100) /
      (data.numerrors + data.nummedicoes)
    ).toFixed(2);
    data.errosSM = listErrorsCode.filter((x) => x === '19').length;
    data.errosSMPercent = (
      (data.errosSM * 100) /
      (data.numerrors + data.nummedicoes)
    ).toFixed(2);
    data.errosBorb = listErrorsCode.filter((x) => x === '6').length;
    data.errosBorbPercent = (
      (data.errosBorb * 100) /
      (data.numerrors + data.nummedicoes)
    ).toFixed(2);
    data.errosCabFlat = listErrorsCode.filter((x) => x === '23').length;
    data.errosCabFlatPercent =
      (data.errosCabFlat * 100) /
      (data.numerrors + data.nummedicoes).toFixed(2);
    data.errosOsc = listErrorsCode.filter((x) => x === '17').length;
    data.errosOscPercent = (
      (data.errosOsc * 100) /
      (data.numerrors + data.nummedicoes)
    ).toFixed(2);
    data.errosTemp = listErrorsCode.filter((x) => x === '24').length;
    data.errosTempPercent = (
      (data.errosTemp * 100) /
      (data.numerrors + data.nummedicoes)
    ).toFixed(2);
    data.errosTampFun = listErrorsCode.filter(
      (x) => x === '0' || x === '3' || x === '4'
    ).length;
    data.errosTampFunPercent = (
      (data.errosTampFun * 100) /
      (data.numerrors + data.nummedicoes)
    ).toFixed(2);

    data.sumFail =
      data.errosCelCarga +
      data.errosCupMed +
      data.errosSM +
      data.errosBorb +
      data.errosCabFlat +
      data.errosOsc +
      data.errosTemp +
      data.errosTampFun;
    data.sumFailPercent = (
      (data.sumFail * 100) /
      (data.numerrors + data.nummedicoes)
    ).toFixed(2);

    const umidadeDataset = data.medicoes.map((item) => {
      return item.umidade;
    });

    var MEDIA_SIZE = 10;
    var MAX = 1.0;

    for (var i = MEDIA_SIZE + 1; i < umidadeDataset.length; i++) {
      let arr = [...umidadeDataset].slice(i - MEDIA_SIZE - 1, i - 1);
      let val = parseFloat(umidadeDataset[i - 1]);
      let med = average(arr);
      let abs = Math.abs(val - med).toFixed(2);
      if (abs > MAX) {
        console.log('Find GAP umidade');
      }
    }

    const labelsDataset = data.medicoes.map((item) => {
      return dateformat(item.createdAt, true);
    });

    const umidade = {
      labels: labelsDataset,
      datasets: [
        {
          label: 'Umidade',
          backgroundColor: 'rgba(42,157,143,0.8)',
          borderColor: 'rgba(0,0,0,1)',
          borderWidth: 2,
          data: umidadeDataset
        }
      ]
    };

    const temperaturaDataset = data.medicoes.map((item) => {
      return item.temperatura;
    });

    MEDIA_SIZE = 10;
    MAX = 1.0;

    for (let i = MEDIA_SIZE + 1; i < temperaturaDataset.length; i++) {
      let arr = [...temperaturaDataset].slice(i - MEDIA_SIZE - 1, i - 1);
      let val = parseFloat(temperaturaDataset[i - 1]);
      let med = average(arr);
      let abs = Math.abs(val - med).toFixed(2);
      if (abs > MAX) {
        console.log('Find GAP temperatura');
      }
    }

    const temperatura = {
      labels: labelsDataset,
      datasets: [
        {
          label: 'Temperatura',
          backgroundColor: 'rgba(231,111,81,0.8)',
          borderColor: 'rgba(0,0,0,1)',
          borderWidth: 2,
          data: temperaturaDataset
        }
      ]
    };

    const pesoDataset = data.medicoes.map((item) => {
      return item.peso;
    });

    MEDIA_SIZE = 10;
    MAX = 5.0;

    for (let i = MEDIA_SIZE + 1; i < pesoDataset.length; i++) {
      let arr = [...pesoDataset].slice(i - MEDIA_SIZE - 1, i - 1);
      let val = parseFloat(pesoDataset[i - 1]);
      let med = average(arr);
      let abs = Math.abs(val - med).toFixed(2);
      if (abs > MAX) {
        console.log('Find GAP peso');
      }
    }

    const peso = {
      labels: labelsDataset,
      datasets: [
        {
          label: 'Peso',
          backgroundColor: 'rgba(38,70,83,0.8)',
          borderColor: 'rgba(0,0,0,1)',
          borderWidth: 2,
          data: pesoDataset
        }
      ]
    };

    const freqvDataset = data.medicoes.map((item) => {
      return item.freqv;
    });

    const freqv = {
      labels: labelsDataset,
      datasets: [
        {
          label: 'Frequência vazio',
          backgroundColor: 'rgba(233,196,106,0.8)',
          borderColor: 'rgba(0,0,0,1)',
          borderWidth: 2,
          data: freqvDataset
        }
      ]
    };

    const freqcDataset = data.medicoes.map((item) => {
      return item.freqc;
    });

    const freqc = {
      labels: labelsDataset,
      datasets: [
        {
          label: 'Frequência cheio',
          backgroundColor: 'rgba(244,162,97,0.8)',
          borderColor: 'rgba(0,0,0,1)',
          borderWidth: 2,
          data: freqcDataset
        }
      ]
    };

    const global = {
      labels: labelsDataset,
      datasets: [
        {
          label: 'Umidade',
          fill: false,
          borderColor: 'rgba(42,157,143,0.8)',
          yAxisID: 'y-axis-1',
          data: umidadeDataset
        },
        {
          label: 'Temperatura',
          fill: false,
          borderColor: 'rgba(231,111,81,0.5)',
          yAxisID: 'y-axis-2',
          data: temperaturaDataset
        },
        {
          label: 'Peso',
          fill: false,
          borderColor: 'rgba(38,70,83,0.5)',
          yAxisID: 'y-axis-3',
          data: pesoDataset
        },
        {
          label: 'Frequência Vazio',
          fill: false,
          borderColor: 'rgba(233,196,106,0.5)',
          yAxisID: 'y-axis-4',
          data: freqvDataset
        },
        {
          label: 'Frequência Cheio',
          fill: false,
          borderColor: 'rgba(244,162,97,0.5)',
          yAxisID: 'y-axis-5',
          data: freqcDataset
        }
      ]
    };

    const erros = {
      labels: data.formattedErrors.map((item) => {
        return item[0];
      }),
      datasets: [
        {
          label: 'Erros',
          borderColor: 'rgba(0,0,0,1)',
          borderWidth: 2,
          backgroundColor: [
            '#BDB76B',
            '#DAA520',
            '#B8860B',
            '#8B4513',
            '#A0522D',
            '#BC8F8F',
            '#CD853F',
            '#D2691E',
            '#F4A460',
            '#FFDEAD',
            '#F5DEB3',
            '#DEB887',
            '#D2B48C'
          ],
          data: data.formattedErrors.map((item) => {
            return item[1];
          })
        }
      ]
    };

    var arr = data.medicoes.map((item) => {
      return parseInt(item.umidade);
    });

    var max = arr.reduce((a, b) => {
      return Math.max(a, b);
    });

    var min = arr.reduce((a, b) => {
      return Math.min(a, b);
    });

    data.umidade = umidade;
    data.erros = erros;
    data.temperatura = temperatura;
    data.peso = peso;
    data.freqv = freqv;
    data.freqc = freqc;
    data.global = global;

    let optionsUmidade = {
      title: {
        display: true,
        text: 'Umidade',
        fontSize: 20
      },
      legend: {
        display: true,
        position: 'right'
      },
      scales: {
        yAxes: [
          {
            ticks: {
              suggestedMin: min - 1,
              suggestedMax: max + 1
            }
          }
        ]
      }
    };

    arr = data.medicoes.map((item) => {
      return parseInt(item.temperatura);
    });

    max = arr.reduce((a, b) => {
      return Math.max(a, b);
    });

    min = arr.reduce((a, b) => {
      return Math.min(a, b);
    });

    let optionsTemperatura = {
      title: {
        display: true,
        text: 'Temperatura',
        fontSize: 20
      },
      legend: {
        display: true,
        position: 'right'
      },
      scales: {
        yAxes: [
          {
            ticks: {
              suggestedMin: min - 1,
              suggestedMax: max + 1
            }
          }
        ]
      }
    };

    let optionsPeso = {
      title: {
        display: true,
        text: 'Peso',
        fontSize: 20
      },
      legend: {
        display: true,
        position: 'right'
      },
      scales: {
        yAxes: [
          {
            ticks: {
              // suggestedMin: 200,
              // suggestedMax: 300
            }
          }
        ]
      }
    };

    let optionsFreqv = {
      title: {
        display: true,
        text: 'Frequência Vazio',
        fontSize: 20
      },
      legend: {
        display: true,
        position: 'right'
      },
      scales: {
        yAxes: [
          {
            ticks: {
              // suggestedMin: 10500,
              // suggestedMax: 10700
            }
          }
        ]
      }
    };

    let optionsFreqc = {
      title: {
        display: true,
        text: 'Frequência Cheio',
        fontSize: 20
      },
      legend: {
        display: true,
        position: 'right'
      },
      scales: {
        yAxes: [
          {
            ticks: {
              // suggestedMin: 10500,
              // suggestedMax: 11000
            }
          }
        ]
      }
    };

    let optionsGlobal = {
      title: {
        display: true,
        text: 'Todos',
        fontSize: 20
      },
      legend: {
        display: true,
        position: 'right'
      },
      scales: {
        yAxes: [
          {
            type: 'linear',
            display: true,
            position: 'right',
            id: 'y-axis-1'
          },
          {
            type: 'linear',
            display: true,
            position: 'right',
            id: 'y-axis-2',
            gridLines: {
              drawOnArea: false
            }
          },
          {
            type: 'linear',
            display: true,
            position: 'right',
            id: 'y-axis-3',
            gridLines: {
              drawOnArea: false
            }
          },
          {
            type: 'linear',
            display: true,
            position: 'right',
            id: 'y-axis-4',
            gridLines: {
              drawOnArea: false
            }
          },
          {
            type: 'linear',
            display: true,
            position: 'right',
            id: 'y-axis-5',
            gridLines: {
              drawOnArea: false
            }
          }
        ]
      }
    };

    data.optionsUmidade = optionsUmidade;
    data.optionsTemperatura = optionsTemperatura;
    data.optionsPeso = optionsPeso;
    data.optionsFreqv = optionsFreqv;
    data.optionsFreqc = optionsFreqc;
    data.optionsGlobal = optionsGlobal;

    data.serialnumber = serial;

    setData(data);
  };

  const handleFieldChange = (event, field, value) => {
    event.persist && event.persist();
    if (field === 'daystoactivate')
      value = value <= 1 ? 1 : value > 30 ? 30 : value;
    setData((values) => ({
      ...values,
      [field]: value
    }));
  };

  const handleIdChange = (event) => {
    setSelectedId(event.target.value);
    filterData({ ...allData }, event.target.value);
  };

  return (
    <Page className={classes.root} title="Equipamentos">
      <Header />

      {loading ? (
        <div style={{ textAlign: 'center', marginTop: 30 }}>
          <CircularProgress />
        </div>
      ) : null}

      <Form
        className={classes.aboutProject}
        data={data}
        fieldChange={handleFieldChange}
        handleIdChange={handleIdChange}
        selectedId={selectedId}
      />
    </Page>
  );
};

export default EquipmentsDetailQL;
