import React from 'react';
import { Page } from 'components';
import Header from "../../components/Header/Header"
import { Form, Card, Row, Col, Button, FloatingLabel } from "react-bootstrap";

const Settings = (props) => {

  const user = JSON.parse(localStorage.getItem('userData'))
  if (user?.permission?.general?.read === false) {
    window.location.replace('/home');
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log("Formulário enviado");
  };

  return (
    <Page className="p-3" title="Configuração">
      <Header page={"Configuração"} />
      <Card className='mb-3 mt-2 p-3'>
        <Row className="g-2">
          <Col md>
            <FloatingLabel controlId="floatingInput" label="Nome" className="mb-3">
              <Form.Control
                type="text"
                name="nome"
                placeholder="Nome"
                value={user?.name || ''}
                disabled
              />
            </FloatingLabel>
          </Col>
          <Col md>
            <FloatingLabel controlId="floatingInput" label="E-mail" className="mb-3">
              <Form.Control
                type="email"
                name="E-mail"
                placeholder="E-mail"
                value={user?.email || ''}
                disabled
              />
            </FloatingLabel>
          </Col>
          <Col md>
            <FloatingLabel controlId="floatingInput" label="Telefone" className="mb-3">
              <Form.Control
                type="text"
                name="Telefone"
                placeholder="Telefone"
                value={user?.fone || ''}
                disabled
              />
            </FloatingLabel>
          </Col>
        </Row>

        <Row className="g-2">
          <Col md>
            <FloatingLabel controlId="floatingInput" label="CPF" className="mb-3">
              <Form.Control
                type="text"
                name="cpf"
                placeholder="CPF"
                value={user?.cpfcnpj || ''}
                disabled
              />
            </FloatingLabel>
          </Col>
          <Col md>
            <FloatingLabel controlId="floatingInput" label="Empresa" className="mb-3">
              <Form.Control
                type="text"
                name="empresa"
                placeholder="Empresa"
                value={user?.company || ''}
                disabled
              />
            </FloatingLabel>
          </Col>
          <Col md>
            <FloatingLabel controlId="floatingInput" label="País" className="mb-3">
              <Form.Control
                type="text"
                name="país"
                placeholder="País"
                value={user?.country || ''}
                disabled
              />
            </FloatingLabel>
          </Col>
        </Row>
        <Row>
          <Col>
            <Button
              variant="success"
              disabled
            >
              Salvar
            </Button>
          </Col>
        </Row>
      </Card>
    </Page>
  );
};

export default Settings;
