import React, { useState, useEffect } from 'react';
import dateformat from 'utils/dateformat';
import { Map, Filter } from './components';
import Reload from '../../components/Reload/Reload';
import ApiMServer from 'services/ApiMServer';

const GOOGLE_API_KEY = process.env.REACT_APP_GOOGLE_API_KEY;

const MapsUnits = () => {
  const user = JSON.parse(localStorage.getItem('userData')) || {};

  // Redirecionar se o usuário não tiver permissão para mapas
  useEffect(() => {
    if (user?.permission?.mapsunits?.read !== true) {
      window.location.replace('/home');
    }
  }, [user]);

  const [zoom] = useState(5);
  const [points, setPoints] = useState([]);
  const [center] = useState({ lat: -14.8227361, lng: -54.1549677 });
  const [loading, setLoading] = useState(true);

  const fetchData = async () => {
    setLoading(true);
    try {
      const { data } = await ApiMServer.get('/company/getdetail');
      const formattedData = formatUnitsData(data);
      setPoints(formattedData);
    } catch (error) {
      console.error('Erro ao buscar dados:', error);
    } finally {
      setLoading(false);
    }
  };

  const formatUnitsData = (units) => {
    return units
      .map((unit) => {
        const lat = parseFloat(unit?.location?.lat).toFixed(7);
        const lng = parseFloat(unit?.location?.lng).toFixed(7);

        if (isNaN(lat) || isNaN(lng) || lat === 0 || lng === 0) return;

        return {
          type: 'Feature',
          properties: {
            cluster: false,
            _id: unit._id,
            name: unit.name || '',
            data: {
              _id: unit._id,
              name: unit.name || '',
              cnpj: unit.cnpj || '',
              fone: unit.fone || '',
              date: dateformat(unit.updatedAt),
              email: unit.email || '',
              address: unit.address || '',
              typeCompany: unit.typeCompany || false,
              equipments: unit.equipments || [],
              lat: parseFloat(lat),
              lng: parseFloat(lng),
            },
          },
          geometry: {
            type: 'Point',
            coordinates: [parseFloat(lng), parseFloat(lat)],
          },
        };
      }).filter(Boolean);
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <div style={{ width: '100%', height: '100vh', backgroundColor: '#292E29' }}>
      {loading && <Reload />}

      <Filter /* filter={filter} companyNames={companyNames} */ count={points.length} />

      {points.length > 0 && (
        <Map
          center={center}
          containerElement={<div style={{ height: '100%' }} />}
          googleMapURL={`https://maps.googleapis.com/maps/api/js?v=3.exp&libraries=geometry,drawing,places&key=${GOOGLE_API_KEY}&language=pt-br`}
          loadingElement={<div style={{ height: '100%' }} />}
          mapElement={<div style={{ height: '100%' }} />}
          points={points}
          zoom={zoom}
        />
      )}
    </div>
  );
};

export default MapsUnits;
