import React, { useState } from 'react';
import { Row, Col } from 'react-bootstrap';

const Filter = ({ count }) => {

  return (
    <div
      className='p-2 filter-maps'
      style={{
        position: 'fixed',
        top: 0,
        left: 220,
        right: 0,
        zIndex: 1000,
        backgroundColor: '#0c392e96',
        color: 'white',
      }}
    >
      <Row className="align-items-center">
        <Col xs="auto">
          <strong style={{ color: 'white' }}>TOTAL: {count || 0}</strong>
        </Col>
      </Row>
    </div>
  );
};

export default Filter;
