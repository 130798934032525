import React, { useEffect } from "react";
import { Card, Button } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';

const InfoWindow = ({ close, data, lat, lng, setCenter }) => {
  const history = useHistory();

  const handleClick = (data) => {
    if (data.serial) {
      history.push(`/equipments/${data.serial}`);
    }
  };

  useEffect(() => {
    setCenter({
      lat: lat,
      lng: lng
    });
  }, [setCenter, lat, lng]);

  return (
    <Card
      border="success"
      style={{
        position: "relative",
        width: 375,
        transform: "translate(-50%, -100%)",
        backgroundColor: "rgba(255, 255, 255, 0.85)", // Transparência
        boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.2)", // Sombra sutil
        borderRadius: "12px", // Bordas arredondadas
        border: "1px solid rgba(0, 0, 0, 0.1)", // Linha sutil
        zIndex: 999,
        backdropFilter: "blur(6px)", // Desfoque no fundo
      }}
    >
      <Card.Header style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
        <h6 className="mt-1 mb-1">{data.serial || "--"}</h6>
        <Button
          variant="outline-secondary"
          size="sm"
          onClick={close}
          style={{ borderRadius: "50%", padding: "2px 6px", fontSize: "12px" }}
        >
          ✕
        </Button>
      </Card.Header>
      <Card.Body>
        <p style={{ margin: "4px 0", fontSize: 15 }}>
          <strong>{data.isConnected ? "Online" : "Offline"}</strong>
        </p>
        <p style={{ margin: "4px 0" }}>
          <strong>Locação:</strong> {data.located ? "Sim" : "Não"}
        </p>
        <p style={{ margin: "4px 0" }}>
          <strong>Versão do Software:</strong> {data.versaosw || "--"}
        </p>
        <p style={{ margin: "4px 0" }}>
          <strong>Versão do Firmware Osciladora:</strong> {data.versaofwosc || "--"}
        </p>
        <p style={{ margin: "4px 0" }}>
          <strong>Versão do Firmware Principal:</strong> {data.versaofwpri || "--"}
        </p>
      </Card.Body>
      <Card.Footer>
        <Button
          size="sm"
          variant="secondary"
          onClick={() => {
            handleClick(data);
          }}
        >
          Ver
        </Button>
      </Card.Footer>
    </Card>
  );
};

export default InfoWindow;
