import React, { useState, useCallback } from 'react';
import { Card, Button, Table, Pagination } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';

const Results = ({ data, onRemoveButton, permission }) => {
  const history = useHistory();

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(20);

  const handleChangePage = useCallback((newPage) => {
    setPage(newPage);
  }, []);

  const handleChangeRowsPerPage = useCallback((event) => {
    setRowsPerPage(Number(event.target.value));
  }, []);

  const totalPages = Math.ceil(data.length / rowsPerPage);
  const paginatedData = data.slice(page * rowsPerPage, (page + 1) * rowsPerPage);

  const getPaginationRange = () => {
    const start = Math.max(page - 2, 0);
    const end = Math.min(page + 2, totalPages - 1);
    let range = [];

    for (let i = start; i <= end; i++) {
      range.push(i);
    }
    return range;
  };

  const handleRowClick = (id) => {
    history.push(`/equipments/${id}`);
  };

  return (
    <div>
      <p>
        {data.length} registros encontrados. Página {page + 1} de {totalPages}
      </p>
      <Card>
        <Card.Header>Equipamentos</Card.Header>
        <Card.Body>
          <Table style={{ fontSize: 15 }} striped bordered hover="true" responsive size='sm'>
            <thead>
              <tr className="table-secondary">
                <th className='text-center'>SERIAL</th>
                <th className='text-center'>ATIVAÇÃO</th>
                <th className='text-center'>MODO</th>
                <th className='text-center'>PLANO</th>
                <th className='text-center'>VERSÃO SOFTWARE</th>
                <th className='text-center'>WI-FI</th>
                <th className='text-center'>PH CALIBRADO</th>
                <th className='text-center'>DATA CALIBRAÇÃO</th>
                <th className='text-center'>ISENÇÃO DE TAXAS</th>
                <th className='text-center'>AÇÃO</th>
              </tr>
            </thead>
            <tbody>
              {paginatedData.map((item) => (
                <tr
                  key={item._id}
                  className="clickable-row"
                  onClick={() => handleRowClick(item.serialnumber)}
                  style={{ cursor: 'pointer' }}
                  hover="true"
                  disabled={!permission?.read}
                >
                  <td className='text-center pt-2'>{item.serialnumber || '--'}</td>
                  <td className='text-center pt-2'>{parseInt(item.daystoactivate, 16)} dias</td>
                  <td className='text-center pt-2'>{item.mode === 'RENTAL' ? 'Locação' : 'Proprietário'}</td>
                  <td className='text-center pt-2'>{item.plan || 'NORMAL'}</td>
                  <td className='text-center pt-2'>{item.versaosw || '--'}</td>
                  <td className='text-center pt-2'>{item.wf === 'OK' ? 'Sim' : 'Não'}</td>
                  <td className='text-center pt-2'>{item.calph === 'OK' ? 'Sim' : 'Não'}</td>
                  <td className='text-center pt-2'>
                    {(() => {
                      var d = new Date(item.dcal);
                      let seconds = Math.floor((Date.now() - d.getTime()) / 1000);
                      return seconds > 31536000 ? <div style={{ color: "red" }}>{item.dcal || '--'}</div> : <div>{item.dcal || '--'}</div>
                    })()}
                  </td>
                  <td className='text-center pt-2'>{item?.isentkey === true ? 'Sim' : 'Não'}</td>
                  <td className='text-center'>
                    <Button
                      variant="outline-danger"
                      size="sm"
                      className='ms-2'
                      onClick={(e) => {
                        e.stopPropagation();
                        onRemoveButton(item._id);
                      }}
                      disabled={!permission?.write}
                    >
                      Remover
                    </Button>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </Card.Body>
        <Card.Footer className="d-flex justify-content-between align-items-center">
          <div>
            <label>
              Linhas por página:{' '}
              <select
                value={rowsPerPage}
                onChange={handleChangeRowsPerPage}>
                {[10, 20, 40].map((option) => (
                  <option key={option} value={option}>
                    {option}
                  </option>
                ))}
              </select>
            </label>
          </div>
          <Pagination className="custom-pagination" variant="dark">
            <Pagination.Prev
              onClick={() => handleChangePage(page - 1)}
              disabled={page === 0}
              aria-label="Página anterior"

            />
            {getPaginationRange().map((index) => (
              <Pagination.Item
                key={index}
                active={index === page}
                onClick={() => handleChangePage(index)}
              >
                {index + 1}
              </Pagination.Item>
            ))}
            <Pagination.Next
              onClick={() => handleChangePage(page + 1)}
              disabled={page >= totalPages - 1}
              aria-label="Próxima página"

            />
          </Pagination>
        </Card.Footer>
      </Card>
    </div>
  );
};

export default Results;
