import React from 'react';
import { Card, Table } from 'react-bootstrap';
import dateformat from 'utils/dateformat';

const Results = (props) => {
  const { data, title } = props;

  return (
    <div className="results-root">
      {data.length > 0 ? (
        <Card>
          <Card.Header>{title}</Card.Header>
          <Card.Body className="p-0">
            <Table style={{ fontSize: "0.9rem" }} striped bordered hover="true" responsive size='sm'>
              <thead>
                <tr className="table-secondary">
                  <th className="text-center col-sm-1">SERIAL</th>
                  <th className="text-center">SOFTWARE</th>
                  <th className="text-center">DATA</th>
                  <th className="text-center">E-MAIL</th>
                  <th className="text-center">ENDEREÇO</th>
                  <th className="text-center">LATITUDE</th>
                  <th className="text-center">LONGITUDE</th>
                </tr>
              </thead>
              <tbody>
                {data.map((item) => (
                  <tr key={item._id}>
                    <td className="text-center">{item?.serialnumber || '--'}</td>
                    <td className="text-center">{item?.versaosw || '--'}</td>
                    <td className="text-center">{item?.createdAt ? dateformat(item.createdAt, "UTC:dd/mm/yy' 'HH:MM") : '--'}</td>
                    <td className="text-center">{item?.email || '--'}</td>
                    <td className="text-center">{item?.address || '--'}</td>
                    <td className="text-center">{item?.lat || '--'}</td>
                    <td className="text-center">{item?.lng || '--'}</td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </Card.Body>
        </Card>
      ) : (
        <h3 className="text-center">Sem Dados Registrados!</h3>
      )}
    </div>
  );
};

export default Results;
