import React, { useState } from 'react';
import { Page } from 'components';
import { NotificationForm } from './components';
import ModalAlert from 'components/ModalAlert';
import NotifyModal from 'components/NotifyModal';
import Header from '../../components/Header/Header';

const Notifications = (props) => {

  const [type, setType] = useState({ "color": "", "status": "" });
  const [modalShow, setModalShow] = useState(false);
  const [showModalAlert, setShowModalAlert] = useState(false);
  const [message, setMessage] = useState("");

  const user = JSON.parse(localStorage.getItem('userData'));
  if (user?.permission?.notifications?.read !== true) {
    window.location.replace('/home');
  }

  const handleModalAlert = (msg) => {
    setMessage(msg);
    setShowModalAlert(true)
  }

  const handleAlertModalSuccess = (msg) => {
    setType({ "color": "#198754", "status": msg })
    setModalShow(true)
  }

  const handleAlertModalError = (msg) => {
    setType({ "color": "red", "status": msg })
    setModalShow(true)
  }

  return (
    <Page className="p-3" title="Notificações">
      <Header page={'Notificações'} />

      <ModalAlert
        message={message}
        showModalAlert={showModalAlert}
        onHide={() => setShowModalAlert(false)}
      />

      <NotifyModal
        type={type}
        show={modalShow}
        onHide={() => setModalShow(false)}
      />

      <NotificationForm
        handleModalAlert={handleModalAlert}
        handleAlertModalSuccess={handleAlertModalSuccess}
        handleAlertModalError={handleAlertModalError}
      />

    </Page>
  );
};

export default Notifications;
