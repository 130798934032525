import React, { useState, useRef } from 'react';
import useSupercluster from "use-supercluster";
import GoogleMapReact from "google-map-react";
import { Marker } from 'react-google-maps';
import InfoWindow from '../InfoWindow';
import './Map.css'

const Map = (props) => {
  const mapRef = useRef();

  const { points, mapPoints } = props
  const [bounds, setBounds] = useState(null)
  const [zoom, setZoom] = useState(5)

  const [center, setCenter] = useState({
    lat: -14.4284,
    lng: -55.2733
  });

  const handleCloseInfoWindown = () => {
    setSelectedEquipment(null);
    setCenter({
      lat: -14.4284,
      lng: -55.2733
    });
    setZoom(5);
  }

  const { clusters, supercluster } = useSupercluster({
    points,
    bounds,
    zoom,
    options: { radius: 30, maxZoom: 15 }
  });

  const [selectedEquipment, setSelectedEquipment] = useState(null)

  return (
    <div style={{ height: '100%', width: '100%' }}>

      <GoogleMapReact
        bootstrapURLKeys={{ key: process.env.REACT_APP_GOOGLE_API_KEY }}
        defaultCenter={{
          lat: -14.4284,
          lng: -55.2733
        }}
        center={center}
        zoom={zoom}
        defaultZoom={4}
        yesIWantToUseGoogleMapApiInternals
        onGoogleApiLoaded={({ map }) => {
          mapRef.current = map;
        }}
        onChange={({ zoom, bounds }) => {
          setZoom(zoom);
          setBounds([
            bounds.nw.lng,
            bounds.se.lat,
            bounds.se.lng,
            bounds.nw.lat
          ]);
        }}
      >
        {clusters.map(marker => {
          const [longitude, latitude] = marker.geometry.coordinates;
          const {
            cluster: isCluster,
            point_count: pointCount
          } = marker.properties;

          if (mapPoints === true) {
            return (
              <Marker
                key={marker.id || `marker-${latitude}-${longitude}`}
                onClick={() => {
                  setSelectedEquipment(marker);
                }}
                lat={latitude}
                lng={longitude}
              >
                <img
                  className=""
                  alt=""
                  src={marker ? '/images/redMarker.png' : ''}
                  onClick={() => {
                    setSelectedEquipment(marker.properties.data)
                  }} />
              </Marker>
            )
          }

          if (isCluster) {
            return (
              <Marker
                key={marker.id || `marker-${latitude}-${longitude}`}
                onClick={() => {
                  setSelectedEquipment(marker);
                }}
                lat={latitude}
                lng={longitude}
              >
                <div
                  className="cluster"
                  style={{
                    "width": `${15 + (pointCount / points.length) * 30}px`,
                    "height": `${15 + (pointCount / points.length) * 30}px`,
                    "zIndex": 9999
                  }}
                  onClick={() => {
                    const expansionZoom = Math.min(
                      supercluster.getClusterExpansionZoom(marker.id),
                      20
                    );
                    mapRef.current.setZoom(expansionZoom);
                    mapRef.current.panTo({ lat: latitude, lng: longitude });
                  }}
                >
                  {pointCount}
                </div>
              </Marker>
            )
          }

          return (
            <Marker
              key={marker.id || `marker-${latitude}-${longitude}`}
              lat={latitude}
              lng={longitude}
            >
              <div className="marker-container" style={{ position: 'relative' }}>
                {marker?.properties?.data?.isConnected ?
                  <div
                    className={'badge-online'}
                    style={{
                      position: 'absolute',
                      top: '-15px',
                      left: '50%',
                      transform: 'translateX(-50%)',
                      backgroundColor: '#4CAF50',
                      color: 'white',
                      fontSize: '10px',
                      padding: '2px 6px',
                      borderRadius: '10px',
                      zIndex: 998,
                      boxShadow: '0 2px 4px rgba(0, 0, 0, 0.2)',
                      border: "solid 1px white"
                    }}
                  >
                    {"Online"}
                  </div>
                  : null
                }

                <span className="marker-label">{marker?.properties?.data?.serial}</span>
                <img
                  alt="img"
                  src={
                    marker.properties.data.serial.startsWith("CP")
                      ? '/images/cp.png'
                      : marker.properties.data.serial.startsWith("SI") || marker.properties.data.serial.startsWith("ES")
                        ? '/images/esi.png'
                        : marker.properties.data.serial.startsWith("BI") || marker.properties.data.serial.startsWith("FB")
                          ? '/images/fbi.png'
                          : marker.properties.data.serial.startsWith("EE")
                            ? '/images/ee.png'
                            : '/images/999dry.png'
                  }
                  className={`marker ${marker.located ? 'equip_marker_img_located' : 'equip_marker_img'}`}
                  onClick={() => {
                    setSelectedEquipment(marker.properties.data);
                  }}
                />
              </div>
            </Marker>
          )
        })}

        {selectedEquipment &&
          <InfoWindow
            close={handleCloseInfoWindown}
            data={selectedEquipment}
            lat={selectedEquipment.lat}
            lng={selectedEquipment.lng}
            setCenter={setCenter}
          />
        }

      </GoogleMapReact>
    </div>
  )
}
export default Map;
