import React, { useState, useEffect } from 'react';
import { Page, NotifyModal } from 'components';
import Header from '../../components/Header/Header';
import FileUpload from 'components/FileUpload';
import { Alert, Card } from 'react-bootstrap';

const Charts = () => {
  const user = JSON.parse(localStorage.getItem('userData'))
  if (user?.permission?.charts?.read !== true || user?.permission?.charts?.write !== true) {
    window.location.replace('/home');
  }

  const [permission, setPermission] = useState(false);
  const [type, setType] = useState({ color: "", status: "" });
  const [modalShow, setModalShow] = useState(false);

  const handleAlertModalSuccess = (msg) => {
    setType({ "color": "#198754", "status": msg })
    setModalShow(true)
  }

  const handleAlertModalError = (msg) => {
    setType({ "color": "red", "status": msg })
    setModalShow(true)
  }

  useEffect(() => {
    setPermission(user?.permission?.units?.write)
  }, [])

  return (
    <Page className="p-3" title="Curvas">
      <Header page={"Curvas"} />

      <Card className='mb-3 mt-3 p-3'>
        <NotifyModal
          type={type}
          show={modalShow}
          onHide={() => setModalShow(false)}
        />

        <Alert variant="warning" className='p-2'>
          <Alert.Heading>Atenção!</Alert.Heading>
          <p>
            Ao enviar a nova curva, a curva atual será substituída definitivamente! Certifique-se de que os dados estão corretos antes de prosseguir.
          </p>
          <hr />
          <p className="mb-0">
            Todos os equipamentos com conexão à internet serão atualizados automaticamente.
          </p>
        </Alert>

        <hr />

        {/* Type = CUSTOM, INMETRO, COMPLEMENT */}
        <h4>Curva Inmetro</h4>
        <FileUpload
          msg={"Enviar a nova curva substituirá a curva INMETRO atual!"}
          type={"INMETRO"}
          permission={permission}
          handleAlertModalError={handleAlertModalError}
          handleAlertModalSuccess={handleAlertModalSuccess}
        />

        <hr />

        <h4>Curva Complementar</h4>
        <FileUpload
          msg={"Enviar a nova curva substituirá a curva COMPLEMENTAR atual!"}
          type={"COMPLEMENT"}
          permission={permission}
          handleAlertModalError={handleAlertModalError}
          handleAlertModalSuccess={handleAlertModalSuccess}
        />

      </Card>
    </Page>
  );
};

export default Charts;
